<template>
  <div
    :class="{ 'u-overlay--center': !model.disableOverlay }"
    class="c-full-width-block u-flex u-flex-justify-center u-flex-align-center"
  >
    <div class="c-full-width-block-media">
      <Picture
        v-if="model.image && !model.video"
        v-bind="model.image"
        full-width
        class="c-full-width-block-image"
      />
      <CVideo
        v-if="model.video"
        :src="model.video.src"
        :type="model.video.type"
        :heading="model.video.heading"
        :poster="model.image ? model.image.src : null"
        autoplay
        loop
        muted
        class="c-full-width-block-video"
      />
    </div>
    <div class="c-full-width-block-inner">
      <div class="c-full-width-block-content u-text-align--center">
        <strong
          v-if="model.subtitle"
          class="c-full-width-block-inner-subheading"
        >
          {{ model.subtitle }}
        </strong>
        <h2
          v-if="model.title"
          class="c-full-width-block-inner-heading e-heading u-mb--s"
        >
          {{ model.title }}
        </h2>
        <p
          v-if="model.text"
          class="c-full-width-block-inner-description e-description u-mb--m"
        >
          {{ model.text }}
        </p>
        <Link
          v-if="model.link && model.link.url"
          :model="model.link"
          class="c-button"
        />
      </div>
    </div>
  </div>
</template>

<script>

import Link from '@/components/Link.vue'
import Picture from '@/components/Picture.vue'
import CVideo from '@/components/Video.vue'

export default {
  components: {
    Link,
    Picture,
    CVideo
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  }
}
</script>
