import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

const scrollOptions = {
  reserveScrollBarGap: true,
  allowTouchMove: (element) => {
    let el = element
    while (el && el !== document.body) {
      if (el.getAttribute('body-scroll-lock-ignore') !== null) {
        return true
      }
      el = el.parentElement
    }
    return false
  }
}

// eslint-disable-next-line import/no-mutable-exports
let instance = null

class NavigationHandler {
  constructor() {
    this.topNavSelector = null
    this.menuScrollSelector = null
    this.menuScrollSelector = null
    this.navToggleClass = null
    this.marketSelectorToggleClass = null
    this.elements = []
    this.overlay = null
    this.topNav = null
    this.topNavMenuButton = null
    this.topNavShareButton = null
    this.topNavCloseButton = null
    this.marketSelectorOpenButton = null
    this.marketSelectorCloseButton = null
  }

  init(topNavSelector, navToggleClass) {
    this.navToggleClass = navToggleClass
    this.topNav = this.topNav || document.querySelector(topNavSelector)
  }

  initMenu(topNavCloseButtonSelector, itemSelector, buttonSelector, toggleClass) {
    this.toggleClass = toggleClass

    if (this.topNav) {
      this.topNavCloseButton = this.topNav.querySelector(topNavCloseButtonSelector)

      if (this.topNavCloseButton) {
        this.topNavCloseButton.addEventListener('click', () => {
          this.toggleTopNavClass(true)
        })
      }

      this.elements = this.topNav.querySelectorAll(itemSelector)
      this.elements.forEach((element) => {
        const button = element.querySelector(buttonSelector)

        if (button) {
          button.addEventListener('click', () => {
            element.classList.toggle(this.toggleClass)
          })
        }
      })
    }
  }

  initMarketSelector(marketSelectorOpenButtonSelector, marketSelectorCloseButtonSelector, marketsSelector, toggleClass) {
    this.marketSelectorToggleClass = toggleClass

    if (this.topNav) {
      this.marketSelectorOpenButton = this.topNav.querySelector(marketSelectorOpenButtonSelector)
      this.marketSelectorCloseButton = this.topNav.querySelector(marketSelectorCloseButtonSelector)

      if (this.marketSelectorOpenButton) {
        this.marketSelectorOpenButton.addEventListener('click', () => {
          this.toggleMarketSectorClass()
        })
      }

      if (this.marketSelectorCloseButton) {
        this.marketSelectorCloseButton.addEventListener('click', () => {
          this.toggleMarketSectorClass(true)
        })
      }
    }
  }

  initTopNav(topNavMenuButtonSelector, overlaySelector, menuScrollSelector) {
    this.menuScrollSelector = menuScrollSelector

    if (this.topNav) {
      this.overlay = this.topNav.querySelector(overlaySelector)
      this.topNavMenuButton = this.topNav.querySelector(topNavMenuButtonSelector)

      if (this.overlay) {
        this.overlay.addEventListener('click', (e) => {
          if (e.target === this.overlay) {
            this.toggleTopNavClass()
          }
        })
      }

      if (this.topNavMenuButton) {
        this.topNavMenuButton.addEventListener('click', () => {
          this.toggleTopNavClass()
        })
      }
    }
  }

  toggleTopNavClass(forceRemove) {
    if (this.topNav.classList.contains(this.navToggleClass) || forceRemove) {
      this.topNav.classList.remove(this.navToggleClass)
      enableBodyScroll(this.menuScrollSelector)
      this.toggleMarketSectorClass(true)
    } else {
      this.topNav.classList.add(this.navToggleClass)
      disableBodyScroll(this.menuScrollSelector, scrollOptions)
    }
  }

  toggleMarketSectorClass(forceRemove) {
    if (this.topNav.classList.contains(this.marketSelectorToggleClass) || forceRemove) {
      this.topNav.classList.remove(this.marketSelectorToggleClass)
      this.marketSelectorOpenButton.ariaExpanded = false
    } else {
      this.topNav.classList.add(this.marketSelectorToggleClass)
      this.marketSelectorOpenButton.ariaExpanded = true
    }
  }

  clear() {
    instance = null
    instance = this.constructor()
  }
}

instance = new NavigationHandler()

export default instance
