<template>
  <div>
    <div
      v-if="model"
      class="c-carousel-wrapper u-mb--s"
    >
      <div
        ref="mySlider"
        class="c-carousel c-carousel-media"
        :class="alignedSlides"
      >
        <div
          v-for="(card, index) in model"
          :key="index"
          class="c-carousel__slide"
          :class="{ 'c-carousel__slide--single': cardCount === 1 }"
        >
          <MediaCard
            :index="index"
            :model="card"
            @onHandleClick="onHandleButtonPlay"
          />
        </div>
      </div>
      <div
        ref="mySliderControls"
        class="c-carousel__controls c-carousel__controls--white"
      >
        <SliderControls
          :next-button-text="nextButtonText"
          :previous-button-text="previousButtonText"
        />
      </div>
    </div>
    <Modal
      ref="modal"
      :is-share-button="true"
      :is-full-width-content="true"
      :is-mobile-fullscreen="true"
      :share-button="shareButtonModel"
      :reverse-actions-color="true"
      @onCloseClick="onCloseModalClick"
    >
      <FundamentalBlockLoader
        v-if="visibleModal"
        :model="fundamentalBlockModel"
        :button-text="introSliderButtonText"
      />
    </Modal>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import { useSlider, useSlidesConfig } from '@/composition/slider'
import MediaCard from '@/components/blocks/MediaCard.vue'
import Modal from '@/components/Modal.vue'
import FundamentalBlockLoader from '@/components/blocks/FundamentalBlockLoader.vue'
import SliderControls from '@/components/navigation/SliderControls.vue'
import gtmUtil from '@/utils/gtm-util'

export default {
  components: {
    SliderControls,
    MediaCard,
    Modal,
    FundamentalBlockLoader
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    introSliderButtonText: {
      type: String,
      default: ''
    },
    nextButtonText: {
      type: String,
      default: ''
    },
    previousButtonText: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const mySlider = ref(null)
    const mySliderControls = ref(null)
    const visibleModal = ref(false)
    const fundamentalBlockModel = ref({})
    const shareButtonModel = ref({})
    const modal = ref(null)
    const cardCount = computed(() => props.model.length)

    onMounted(() => {
      useSlider(null, mySlider.value, mySliderControls.value.children[0])
    })

    function onHandleButtonPlay({ modalModel }) {
      fundamentalBlockModel.value = modalModel
      shareButtonModel.value = modalModel.shareButton
      visibleModal.value = true
      modal.value.onOpenModal()
    }

    function onCloseModalClick() {
      gtmUtil.fundamentalsClose()
    }

    return {
      mySlider,
      mySliderControls,
      fundamentalBlockModel,
      modal,
      cardCount,
      shareButtonModel,
      visibleModal,
      onHandleButtonPlay,
      onCloseModalClick,
      ...useSlidesConfig(props, 3)
    }
  }
}
</script>
