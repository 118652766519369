<template>
  <div class="c-recipe-list">
    <article
      v-for="(item, index) in items"
      :key="index"
      class="c-recipe-list__item"
      :class="recipeItemExtraCss(item, index)"
    >
      <a
        v-if="item.uid"
        :href="item.url"
        class="c-recipe-list__item__link"
      >
        <picture
          v-if="item.showGif && item.animatedImage"
          class="c-recipe-list__item__image u-overlay--bottom"
        >
          <img
            :src="item.animatedImage.src.url"
            :alt="item.animatedImage.alt"
          >
        </picture>
        <Picture
          v-else
          v-bind="item.image"
          class="c-recipe-list__item__image u-overlay--bottom"
        />

        <div class="c-recipe-list__item__content u-color--white u-z-index--front">
          <h3 class="c-recipe-list__item__title u-font-size-h4">
            {{ item.heading }}
          </h3>
          <div v-if="item.subHeading">{{ item.subHeading }}</div>
        </div>
      </a>

      <div
        v-else
        class="u-my--l"
      >
        <FullWidthBlock :model="item" />
      </div>
    </article>
  </div>
</template>

<script>
import Picture from '@/components/Picture.vue'
import FullWidthBlock from '@/components/blocks/FullWidthBlock.vue'

export default {
  components: { Picture, FullWidthBlock },
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  setup() {
    const recipeItemExtraCss = (item, index) => {
      let cssClasses = ''

      if ((index + 1) % 5 === 0) {
        cssClasses += ' c-recipe-list__item--full-width'
      }
      if (!item.uid) {
        cssClasses += ' c-recipe-list__item--full-screen-width'
      }
      return cssClasses
    }

    return { recipeItemExtraCss }
  }
}
</script>
