<template>
  <div
    class="c-carousel__navigation u-flex u-flex-align-center"
    aria-label="Carousel Navigation"
    tabindex="0"
  >
    <button
      data-controls="prev"
      aria-controls="customize"
      tabindex="-1"
      class="c-carousel__navigation-button c-carousel__navigation-button-prev"
      :aria-label="previousButtonText"
      @click="onPreviousClick"
    >
      <Icon
        :icon="iconChevronRight"
        :size="20"
        class="c-carousel__button"
      />
    </button>
    <button
      data-controls="next"
      aria-controls="customize"
      tabindex="-1"
      class="c-carousel__navigation-button c-carousel__navigation-button-next"
      :aria-label="nextButtonText"
      @click="onNextClick"
    >
      <Icon
        :icon="iconChevronRight"
        :size="20"
        class="c-carousel__button"
      />
    </button>
  </div>
</template>
<script>
import Icon from '@/components/Icon.vue'
import iconChevronRight from '@/assets/img/icons/chevron--right.svg'

export default {
  components: {
    Icon
  },
  props: {
    nextButtonText: {
      type: String,
      default: ''
    },
    previousButtonText: {
      type: String,
      default: ''
    }
  },
  setup(props, context) {
    const onNextClick = () => {
      context.emit('onNextClick')
    }

    const onPreviuosClick = () => {
      context.emit('onPreviousClick')
    }

    return {
      iconChevronRight,
      onNextClick,
      onPreviuosClick
    }
  }
}
</script>
