<template>
  <div
    v-if="model"
    class="c-carousel-wrapper u-mb--s"
  >
    <div
      ref="mySlider"
      class="c-carousel c-carousel-media-steps"
      :class="alignedSlides"
    >
      <div
        v-for="(card, index) in model"
        :key="index"
        class="c-carousel__slide"
        :class="{ 'c-carousel__slide--single': cardCount === 1 }"
      >
        <MediaStepCard
          :index="index"
          :model="card"
        />
      </div>
    </div>
    <div
      ref="mySliderControls"
      class="c-carousel__controls c-carousel__controls--white"
    >
      <SliderControls
        :next-button-text="nextButtonText"
        :previous-button-text="previousButtonText"
        @onNextClick="onSlideClick('right')"
        @onPreviousClick="onSlideClick('left')"
      />
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import { useMediaStepsSlider, useSlidesConfig } from '@/composition/slider'

import MediaStepCard from '@/components/blocks/MediaStepCard.vue'
import SliderControls from '@/components/navigation/SliderControls.vue'
import gtmUtil from '@/utils/gtm-util'

export default {
  components: {
    SliderControls,
    MediaStepCard
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    nextButtonText: {
      type: String,
      default: ''
    },
    previousButtonText: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const mySlider = ref(null)
    const mySliderControls = ref(null)
    const cardCount = computed(() => props.model.length)

    onMounted(() => {
      useMediaStepsSlider({
        disable: true,
        items: 3
      }, mySlider.value, mySliderControls.value.children[0])
    })

    const onSlideClick = (direction) => {
      gtmUtil.mediaCardSliderSlide(direction)
    }

    return {
      cardCount,
      mySlider,
      mySliderControls,
      onSlideClick,
      ...useSlidesConfig(props, 3)
    }
  }
}
</script>
