<template>
  <a
    :href="model.url"
    class="c-circle-card u-text-align--center"
  >
    <div class="c-circle-card__image-wrapper">
      <Picture
        v-if="model.image"
        v-bind="model.image"
        :mobile="[112,112]"
        class="c-circle-card__image"
      />
    </div>
    <span class="c-circle-card__title">
      {{ model.title }}
    </span>
  </a>
</template>

<script>

import Picture from '@/components/Picture.vue'

export default {
  components: {
    Picture
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  }
}
</script>
