<template>
  <a
    :href="model.url"
    class="c-product-card"
  >
    <Picture
      v-if="model.image"
      v-bind="model.image"
      :mobile="[238,]"
      :tablet="[227,]"
      :desktop="[232,]"
      :wide="[320,]"
      class="c-product-card__image"
    />

    <h3 class="c-product-card__title">
      {{ model.heading }}
    </h3>

    <div
      v-if="model.subHeading"
      class="c-product-card__description"
    >
      {{ model.subHeading }}
    </div>

    <ul
      v-if="model.variants && model.variants.length"
      class="c-product-card__variants"
    >
      <li
        v-for="(variant, index) in model.variants"
        :key="index"
        class="c-product-card__variant"
      >
        <a :href="variant.url">{{ variant.amountUnitText }}</a>
      </li>
    </ul>
  </a>
</template>

<script>
import Picture from '@/components/Picture.vue'

export default {
  components: { Picture },
  props: {
    model: {
      type: Object,
      required: true
    }
  }
}
</script>
